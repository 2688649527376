import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import Enrollment from './Components/Screens/Enrollment/enrollment';
import QuickQuote2 from './Components/Screens/Enrollment/QuickQuote2';
import QuickQuote1 from './Components/Screens/Enrollment/QuickQuote1';
import Header from './Components/Screens/Headers/Header';
import Amplify, { Auth } from 'aws-amplify';
import awsConfig from './awsConfig';
import { withAuthenticator, Authenticator } from 'aws-amplify-react';
import { useState, useEffect } from 'react';
import SignIn from './Components/authentication/SignIn';
import Signup from './Components/authentication/Signup';
import Autologin from "./Components/authentication/autologin";
import QuickEstimate from "./Components/Screens/Enrollment/QuickEstimate/Dashboard/Home"
import TabOpened from './Components/Screens/Enrollment/TabOpened';
global.load=0;

Amplify.configure(awsConfig);

const App = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [tabAccess, setTabAccess] = useState(false);
    const [tabId, setTabid] = useState(null);
    const [currentTabId, setCurrentTabId] = useState(null);
    
    useEffect(() => {
        let tab = Date.now().toString();
        setTabid(tab);
        if (localStorage.getItem("currentTabId") === null) {
            localStorage.setItem("currentTabId", tab);
            setCurrentTabId(tab);
            setShowPopup(false);
        }
        if (sessionStorage.getItem("tab") === null) {
            sessionStorage.setItem("tab", tab);
        }
        const channel = new BroadcastChannel("myChannel");
        channel.postMessage(sessionStorage.getItem("tab"));
        channel.addEventListener("message", (event) => {
            if (
                event.data === sessionStorage.getItem("tab") &&
                sessionStorage.getItem("tabExp") !== "true"
            ) {
                let tabReplace = Date.now().toString();
                localStorage.setItem("currentTabId", tabReplace);
                sessionStorage.setItem("tab", tabReplace);
            }
        });

        if (
            sessionStorage.getItem("tab") &&
            localStorage.getItem("currentTabId") === sessionStorage.getItem("tab")
        ) {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
        const handleStorageChange = (e) => {
            if (e.key === "currentTabId") {
                if (
                    sessionStorage.getItem("tab") &&
                    localStorage.getItem("currentTabId") === sessionStorage.getItem("tab")
                ) {
                    setShowPopup(false);
                } else if (
                    localStorage.getItem("currentTabId") !== sessionStorage.getItem("tab")
                ) {
                    setShowPopup(true);
                    // setTabAccess(true);
                    // sessionStorage.setItem("tabExp", true)
                } else {
                    if (Boolean(sessionStorage.getItem("tabExp"))) {
                        setShowPopup(true);
                        setTabAccess(true);
                    } else setShowPopup(true);
                }
            }
        };
        window.addEventListener("beforeunload", () => {
            if (
                localStorage.getItem("currentTabId") === sessionStorage.getItem("tab")
            ) {
                localStorage.removeItem("currentTabId");
                sessionStorage.removeItem("tab");
            }
        });
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (showPopup || Boolean(sessionStorage.getItem("tabExp")))
        return (
            <TabOpened
                tabAccess={tabAccess || Boolean(sessionStorage.getItem("tabExp"))}
                cancel={() => {
                    setShowPopup(true);
                    setTabAccess(true);
                    sessionStorage.setItem("tabExp", true);
                }}
                onAccept={() => {
                    setShowPopup(false);
                    sessionStorage.setItem("showPopup", false);
                    let tab = Date.now().toString();
                    sessionStorage.setItem("tab", tab);
                    localStorage.setItem("currentTabId", tab);
                    // localStorage.setItem('acceptStatus', 'accepted');
                    // localStorage.removeItem('acceptStatus');
                }}
            />
        );
    else
    return (
        <div className="App">

                <BrowserRouter>
                    <Route exact path="/" component={Enrollment} />
                    <Route path="/login" component={SignIn} />
                    <Route path="/autologin" component={Autologin}></Route>
                    <Route path="/signup" component={Signup} />
                    <Route path="/quick_quote2" component={QuickQuote2} />
                    <Route path="/quick_estimate" component={QuickEstimate} />
                    <Route path="/quick_quote" component={QuickQuote1} />
                </BrowserRouter>
        </div>
    );
}
//export default withAuthenticator(App, true);
export default App;
